<template>
 <b-card>
  <b-card-header>
    <i class="icon-note"></i> Jaminan Emas Transaction Details
    <div class="card-header-actions">
      <a href="jamimas-transaction" class="card-header-action" rel="noreferrer noopener">
      <small class="text-muted">Back</small>
      </a>
    </div>
  </b-card-header>
  <br>
  <table>
    <tr>
      <td><strong>Transaction Date</strong></td>
      <td>{{ this.date }}</td>
    </tr>
    <tr>
      <td><strong>Jamimas ID</strong></td>
      <td>{{ this.id }}</td>
    </tr>
    <tr>
      <td><strong>User</strong></td>
      <td>{{ this.name }} ({{this.email}})</td>
    </tr>
    <tr>
      <td><strong>Gold Amount - Nilai Jaminan Emas</strong></td>
      <td>{{ this.gram }} Gram</td>
    </tr>
    <tr>
      <td><strong>Cashout - Nilai Jaminan Emas</strong></td>
      <td>{{ this.cashout }}</td>
    </tr>
    <tr>
      <td><strong>Gold Sell Price</strong></td>
      <td>{{ this.sellingRate }}</td>
    </tr>
    <tr>
      <td><strong>Tenor(Hari)</strong></td>
      <td>{{ this.tenor }} Hari</td>
    </tr>
    <tr>
      <td><strong>Status</strong></td>
      <td v-if="this.status === 0" >Diproses</td>
      <td v-else-if="this.status === 1">Berjalan</td>
      <td v-else-if="this.status === 2">Masa Tenggang</td>
      <td v-else-if="this.status === 3">Gagal Bayar</td>
      <td v-else-if="this.status === 4">Selesai</td>
      <td v-else-if="this.status === 5">Ditolak - {{ this.reason }}</td>
    </tr>
    <tr>
      <td><strong>Termin Pembayaran</strong></td>
      <td>{{ this.termin }}</td>
    </tr>
    <tr>
      <td><strong>Tagihan Yang Sudah Dibayar</strong></td>
      <td>{{ this.total }}</td>
    </tr>
    <tr>
      <td><strong>Total Biaya Jaminan</strong></td>
      <td>{{ this.interest }}</td>
    </tr>
    <tr>
      <td><strong>Nilai Tagihan</strong></td>
      <td>{{ this.currencyPerPeriod }}</td>
    </tr>
    <tr>
      <td><strong>Tujuan Pencairan Dana</strong></td>
      <td v-if='this.account_number !=="-" || this.bank_name !=="-" || this.owner_name !== "-"'>{{ this.account_number }} - {{ this.bank_name }} - {{ this.owner_name }}</td>
      <td v-else>Celengan</td>
    </tr>
  </table>
  <br>
  <b-card-header>
    <i class="icon-note"></i> Transaction History
  </b-card-header>
  <b-card-body>
    <div>
      <vuetable
       ref="vuetable"
       :api-url="apiUrl"
       :http-options="HttpOptions"
       :fields="fields"
       pagination-path=""
       :muti-sort="true"
       :sort-order="sortOrder"
       :append-params="moreParams"
       @vuetable:pagination-data="onPaginationData"
      >
        <template slot="created_at" slot-scope="prop">
          <span>{{ prop.rowData.created_at }}</span>
        </template>
        <template slot="invoice-slot" slot-scope="prop">
          <span>{{ prop.rowData.invoice_number }}</span>
        </template>
        <template slot="desc-slot" slot-scope="prop">
          <span>{{ prop.rowData.description }}</span>
        </template>
        <template slot="amount-slot" slot-scope="prop">
          <span>{{ prop.rowData.amount }}</span>
        </template>
        <template slot="payment-slot" slot-scope="prop">
          <span>{{ prop.rowData.payment_name === null ? '-' : prop.rowData.payment_name }}</span>
        </template>
      </vuetable>
      <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info
         ref="paginationInfo"
        ></vuetable-pagination-info>
        <vuetable-pagination
         ref="pagination"
         @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
      </div>
    </div>
  </b-card-body>
 </b-card>
</template>

<script>
import accounting from 'accounting'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
export default {
  name: 'detail-request-jamimas',
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo
  },
  data(){
    return {
      date: '',
      id: '',
      name: '',
      email: '',
      gram: '',
      cashout: '',
      sellingRate: '',
      tenor: '',
      status: '',
      currencyPerPeriod: '',
      total: '',
      termin: '',
      interest: '',
      account_number: '',
      bank_name: '',
      owner_name: '',
      reason: '',
      apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `pawn-history/` + this.$route.params.id + `/` + this.$route.params.user_id,
      HttpOptions: {
        headers: {
          'Accept' : 'application/json',
          'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
        }
      },
      sortOrder: [
        {
          field: 'created_at',
          direction: 'desc'
        }
      ],
      moreParams:{},
      fields: [
        {
          name: 'created_at',
          sortField: 'gold_pawn_mutation.created_at',
          title: 'Transaction Date'
        },
        {
          name: 'invoice-slot',
          sortField: 'gold_pawn_transaction.invoice_number',
          title: 'Invoice No'
        },
        {
          name: 'desc-slot',
          sortField: 'gold_pawn_transaction.description',
          title: 'Description'
        },
        {
          name: 'amount-slot',
          sortField: 'gold_pawn_mutation.amount',
          title: 'Transaction Amount'
        },
        {
          name: 'payment-slot',
          sortField: 'payment_method.payment_name',
          title: 'Payment Method'
        }
      ],
      errors: {
        message: [],
        status: [],
        code: '',
        headers: []
      },
      isLoading: false
    }
  },
  created () {
    this.$http.get(`pawn-transaction/` + this.$route.params.id)
    .then((result) => {
      this.date = result.data.data.created_at;
      this.id = result.data.data.pawn_number;
      this.name = result.data.data.users.name;
      this.email = result.data.data.users.email;
      this.gram = result.data.data.gram;
      this.cashout = result.data.data.currency;
      this.sellingRate = result.data.data.selling_rate;
      this.tenor = result.data.data.period_day;
      this.status = result.data.data.status;
      this.currencyPerPeriod = result.data.data.currency_per_period;
      this.total = result.data.data.total;
      this.termin = result.data.data.termin;
      this.interest = result.data.data.interest;
      this.account_number = result.data.data.bank_account.account_number;
      this.bank_name = result.data.data.bank_account.bank_name,
      this.owner_name = result.data.data.bank_account.owner_name,
      this.reason = result.data.data.reason
    }).catch((error) => {
      if(error.response) {
        this.errors.code = error.response.status;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
      }
    })
  },
  methods: {
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if(this.errors.code == 401) {
        if (localStorage.getItem('access_token') != null) {
          localStorage.removeItem('access_token');
          this.$swal.fire(
            'Your session expired!',
            'Your session has expired. please login again to access this page!',
            'error'
          ).then(() => {
            this.$router.push("/login")
          })
        }
      }else if(this.errors.code == 403) {
        this.$router.push("/403")
      }else if(this.errors.code == 500) {
        this.$router.push("/500")
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.onChangePage(page)
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
      this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    formatNumber (value) {
      return 'Rp.'+accounting.formatNumber(value, 2)
    }
  }
}
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
th {
  text-align: left;
}
</style>
